import { dispatchPageBusEvent } from 'client/common/pageBus'

// Listers
export const SEARCH_IMPRESSIONS_PRODUCT = 'SEARCH_IMPRESSIONS_PRODUCT'
export const SEARCH_PRODUCT_CLICKED = 'SEARCH_CLICK_PRODUCT'
export const SEARCH_LIST_TYPE = 'search-results'

export const LISTER_IMPRESSIONS_PRODUCT = 'LISTER_IMPRESSIONS_PRODUCT'
export const LISTER_PRODUCT_CLICKED = 'LISTER_CLICK_PRODUCT'
export const LISTER_LIST_TYPE = 'product-listing'

export const CUSTOM_LISTER_IMPRESSIONS_PRODUCT = 'CUSTOM_LISTER_IMPRESSIONS_PRODUCT'
export const CUSTOM_LISTER_PRODUCT_CLICKED = 'CUSTOM_LISTER_CLICK_PRODUCT'
export const CUSTOM_LIST_TYPE = 'custom-listing'

export const BONUS_BUYS_LISTER_IMPRESSIONS_PRODUCT = 'BONUS_BUYS_LISTER_IMPRESSIONS_PRODUCT'
export const BONUS_BUYS_LISTER_PRODUCT_CLICKED = 'BONUS_BUYS_LISTER_CLICK_PRODUCT'
export const BONUS_BUYS_LIST_TYPE = 'bonus-buys-listing'

// Cross-sells
export const X_SELL_PRODUCT_CLICKED = 'X_SELL_PRODUCT_CLICKED'
export const X_SELL_PRODUCTS_LOADED = 'X_SELL_PRODUCTS_LOADED'

export const CROSS_SELL_LIST_TYPE = 'cross-sell'
export const SERIE_LIST_TYPE = 'serie'
export const ALTERNATIVE_PDP_LIST_TYPE = 'alternatives-PDP'
export const LAST_VIEWED_LISTER_LIST_TYPE = 'last-viewed-lister'
export const LAST_VIEWED_SEARCH_LIST_TYPE = 'last-viewed-search'
export const LAST_VIEWED_ZERO_SEARCH_LIST_TYPE = 'last-viewed-search-zero-results'
export const SOFT_CONTENT_LIST_TYPE = 'soft-content-product-list'

export const HANDLE_FILTER_CLICK = 'handle-filter-click'

export const HANDLE_VIEW_TOP_FILTERS_EVENT = 'handle-view-top-filters-event'
export const SHOW_TOP_FILTER_PAGE_TYPE = 'show-top-filter-page-type'
export const CLICK_STICKY_TOP_FILTERS = 'click-sticky-top-filters'
export const HANDLE_CLICK_STICKY_TOP_FILTERS_EVENT = 'handle-click-sticky-top-filters'
export const HANDLE_VIEW_LISTER_PAGE_EVENT = 'handle-view-lister-page-event'
export const HANDLE_VIEW_REVIEWS_ON_LISTER_EVENT = 'handle-view-reviews-on-lister-event'

export const HANDLE_BUNDLE_CLICK = 'handle-bundle-click'

// Name for short-lived cookie, that should be read in PDP. Reason for creating is that GTM has about 40% unknown add to basket events
export const ANALYTIC_LIST_TYPE_COOKIE = 'analyticListType'

export const analyticForProductClick = (props, index, listType) => {
  const action = {
    type: X_SELL_PRODUCT_CLICKED,
    payload: {
      listType,
      data: {
        ...props,
        index
      }
    }
  }
  dispatchPageBusEvent(action)
}
